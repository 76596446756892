import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Cover from "./components/Cover";
import CoverMobile from "./components/CoverMobile";
import Grow from "./components/Grow";
import Join from "./components/Join";
import Members from "./components/Members";
import "./index.scss";

function People(props: { isMobile: boolean }) {
  const [headerDark, setHeaderDark] = useState(
    !(window.scrollY > window.innerHeight * 0.95)
  );
  useEffect(() => {
    const handleScroll = (e: any) => {
      const newDark = !(window.scrollY > window.innerHeight * 0.95);
      setHeaderDark((prev: boolean) => {
        if ((!newDark && prev) || (newDark && !prev)) {
          return !(window.scrollY > window.innerHeight * 0.95);
        } else return prev;
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="People">
      <Header
        isLinear={true}
        isTransparent={headerDark}
        bgColor={"#ffffff"}
        isDark={headerDark}
        mode="completion"
      />
      {props.isMobile ? <CoverMobile /> : <Cover />}
      <Grow />
      <Members />
      <Join />
      <Footer />
    </div>
  );
}

export default People;
