import React, { Children, ReactElement, useEffect, useState } from "react";
import "./Animation.scss";

function FadeInDown(props: {
  isShow: boolean;
  children: ReactElement | Array<ReactElement>;
}) {
  return (
    <div className={props.isShow ? "fade-in-down" : ""}>{props.children}</div>
  );
}
function Down(props: {
  isShow: boolean;
  children: ReactElement | Array<ReactElement>;
}) {
  const { isShow } = props;
  const [distance, setDistance] = useState(Math.random() * (20 + 1) + 20);
  useEffect(() => {
   isShow?setDistance(0):setDistance(Math.random() * (20 + 1) + 20)
  }, [isShow]);
  return (
    <div
      className="down"
      style={{
        transform: `translateY(-${distance}%)`,
      }}
    >
      {props.children}
    </div>
  );
}
export { FadeInDown, Down };
