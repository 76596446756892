import React from "react";
import "./Title.scss";
interface TitleProp {
  title: string;
  detail: string;
}
function Title(props: TitleProp) {
  const { title, detail } = props;

  return (
    <div className="title-wrap">
      <div className="title-icon"></div>
      <div className="title-content">
        <div className="title-bg"></div>
        <div className="title">{title}</div>
        <div className="title-detail">{detail}</div>
      </div>
    </div>
  );
}

export default Title;
