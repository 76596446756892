import React, { useEffect, useState } from "react";
import "./index.scss";

function CoverMobile() {
  const handleNext = () => {
    window.scrollTo({
      top: 600,
      behavior: "smooth",
    });
  };
  return (
    <div className="CoverMobile">
      <div className="cover-bg">
        <div className="bg-inner">
          <div className="back-circle">
            <div className="circle-item" />
            <div className="circle-item" />
            <div className="circle-item" />
          </div>
        </div>
      </div>
      <div className="cover-content">
        <div className="people-title">
          <p>“</p>
          <p>&nbsp;&nbsp;成长</p>
          <p>&nbsp;&nbsp;&nbsp;是一局团战</p>
          <p>”</p>
        </div>
        <div className="people-subtitle">
          <p>Grow</p>
          <p>&nbsp;Together</p>
        </div>
        <div className="next-arrow" onClick={handleNext} />
      </div>
    </div>
  );
}

export default CoverMobile;
