import React from "react";

import "./index.scss";

import logo from "../../../../assets/people/logo.png";

function Join() {
  return (
    <div className="Join">
      <div className="join-inner">
        <img className="qingyou-logo" src={logo} alt={"Qingyou Studio"} />
        <p className="join-title">与青柚工作室携手</p>
        <p className="join-description">
          遇见和你志同道合的伙伴，获得更多来自前辈的帮助与指导，在交流学习中技术得到提升，在项目的运营中获得满足感，在青柚能发掘你无限的可能。用技术服务更多人，以取悦自己。
        </p>
        <a href="https://forms.njupt.edu.cn/f/EvdwP0" className="join-link">
          加入我们，用热爱创造无限可能
          <svg
            width="12"
            height="21"
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: 10 }}
          >
            <path
              d="M1.4369 0.803223L0.603149 1.63697L9.46628 10.5001L0.603149 19.3632L1.4369 20.197L10.7169 10.917L11.1157 10.5001L10.7169 10.0832L1.4369 0.803223Z"
              fill="#47AE73"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}

export default Join;
