import React from "react";
import { FadeInDown } from "../../../../components/Animation/Animation";
import Title from "../Title/Title";
import passageBg1 from "../../../../assets/index_part4/IMG_2093.png";
import passageBg2 from "../../../../assets/index_part4/IMG_2094.png";
import passageBg3 from "../../../../assets/index_part4/IMG_2095.png";
import "./Part4.scss";
interface PassageProp {
  bg: string;
  title: string;
  detail: string;
}
interface Part4Prop {
  isShow: boolean;
}
function Passage(props: PassageProp) {
  const { bg, title, detail } = props;
  return (
    <div className="passage">
      <div
        className="bg-wrap"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "100% 100%",
        }}
      ></div>
      <div className="passage-content">
        <div className="passage-title">{title}</div>
        <div className="passage-detail">{detail}</div>
      </div>
    </div>
  );
}
const passages = [
  {
    bg: passageBg1,
    title: "《那些年的青柚工作室》",
    detail: "狒狒神叙述他在青柚的美好回忆。",
  },
  {
    bg: passageBg2,
    title: "《青柚「PI」日请你吃PI》",
    detail: "青柚面对全校师生的独家节日PI日吃PI，传递热爱！",
  },
  {
    bg: passageBg3,
    title: "《青柚后端小册》",
    detail: "青柚工作室后端工程师孙晨曦同学主笔创作，带你从零进入后端世界",
  },
];
function Part4(props: Part4Prop) {
  return (
    <div className="part4-wrap container">
      <FadeInDown isShow={props.isShow}>
        <div className="content-wrap">
          <div className="qxy-wrap">
            <div
              className="qxy
        "
            ></div>
          </div>
          <Title
            title="青柚故事：无限热爱与可能"
            detail="不忘初心，以青春之名，为信息化建设进步做贡献"
          ></Title>
          <div className="content">
            <div className="img-wrap"></div>
            <div className="right-wrap">
              <div className="passage-wrap">
                {passages.map((passage, index) => {
                  return (
                    <Passage
                      key={index}
                      bg={passage.bg}
                      title={passage.title}
                      detail={passage.detail}
                    ></Passage>
                  );
                })}
              </div>

              <div className="nav-wrap">
                <div
                  className="nav-content"
                  onClick={() => {
                    let domain = document.domain;
                    let protocol = document.location.protocol;
                    let port = document.location.port;
                    window.location.href = `${protocol}//${domain}${port}/blog`;
                  }}
                >
                  聆听青柚更多故事
                  <div className="nav-icon"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInDown>
    </div>
  );
}

export default Part4;
