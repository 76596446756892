import React, { useState } from "react";
import Title from "../Title";

import { categoryRange, membersList } from "./members";

import "./index.scss";

import membersIcon from "../../../../assets/people/members.svg";
import MemberSelector from "./MemberSelector";
import MemberCard from "./MemberCard";

function Members() {
  const [selected, setSelected] = useState(0);
  return (
    <div className="Members">
      <div className="members-inner">
        <Title
          icon={membersIcon}
          title={"青柚成员"}
          subtitle={"Members of QingYou"}
        />
        <MemberSelector
          range={categoryRange}
          indexSelected={selected}
          onChange={(e: { value: number }) => {
            setSelected(e.value);
          }}
        />
        <div className="members-container">
          {membersList.map((item, index) => {
            if (item.grade === categoryRange[selected])
              return (
                <MemberCard
                  key={`membersList${item.toString()}${index.toString()}`}
                  cover={item.avatar}
                  name={item.name}
                  description={item.description}
                />
              );
          })}
        </div>
      </div>
    </div>
  );
}

export default Members;
