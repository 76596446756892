import React, { useEffect } from "react";

import bg from "../../../../assets/index_part1/bg.png";
import "./Part1.scss";
interface Part1Prop {
  goTo: React.MutableRefObject<undefined>;
  isShow: boolean;
}

function Part1(props: Part1Prop) {
  const { isShow, goTo } = props;
  return (
    <div className="part1-wrap">
      {/* <img src={bg} alt="" /> */}
      <video
        style={{
          width: " auto",
          height: "auto",
          minHeight: "100%",
          minWidth: "100%",
        }}
        autoPlay
        muted
        loop
        preload="metadata"
        src="https://wechat.static.qingyou.ren/qingyou-website/%E9%9D%92%E6%9F%9A%E5%AE%98%E7%BD%91_4.3_x264.mp4"
        poster={bg}
      ></video>
      <div className="banner-wrap">
        <div
          className="banner-item"
          onClick={() => {
            //@ts-ignore
            goTo.current && goTo.current(1);
          }}
        >
          青柚智造
        </div>
        <div
          className="banner-item"
          onClick={() => {
            //@ts-ignore
            goTo.current && goTo.current(2);
          }}
        >
          青柚少年
        </div>
        <div
          className="banner-item"
          onClick={() => {
            //@ts-ignore
            goTo.current && goTo.current(3);
          }}
        >
          青柚故事
        </div>
        <div
          className="banner-item"
          onClick={() => {
            //@ts-ignore
            goTo.current && goTo.current(4);
          }}
        >
          加入我们
        </div>
      </div>
      <div className="divide"></div>
    </div>
  );
}

export default Part1;
