import React, { useLayoutEffect, useState } from "react";

import "./Footer.scss";

import qq_qrcode from "../../assets/qq-qr.png";
import beian from "../../assets/beian.png";

function Footer() {
  const [year, setYear] = useState(2021);
  useLayoutEffect(() => {
    const date = new Date();
    setYear(date.getFullYear());
  }, []);
  return (
    <div className="Footer">
      <div className="footer-inner">
        <div className="footer-top-wrap">
          <div className="footer-title">
            <div className="title-icon" />
            <div className="title-divider" />
            <div className="title-text">用热爱创造无限可能</div>
          </div>
          <div className="qq-connect">
            <img alt="青柚QQ" className="qq-qrcode" src={qq_qrcode} />
          </div>
        </div>
        <div className="footer-info">
          <p>
            联系我们：qingyou@njupt.edu.cn&nbsp;&nbsp;欢迎前往大学生活动中心2楼，与我们线下交流。
          </p>
          <div className="info-divider" />
          <p>Copyright © {year} Qingyou Studio. 保留所有权利。</p>
          <p>
            <img alt="备案" src={beian} />
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011302321449"
              rel="noreferrer"
              target="_blank"
            >
              苏公网安备 32011302321449号
            </a>
            &nbsp;|&nbsp;
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011302321076"
              rel="noreferrer"
              target="_blank"
            >
              苏公网安备 32011302321076号
            </a>
            &nbsp;|&nbsp;
            <a
              href="https://beian.miit.gov.cn/"
              rel="noreferrer"
              target="_blank"
            >
              苏ICP备2020064604号
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
