import React, { useEffect, useState } from "react";

import "./index.scss";

function GrowCard(props: {
  child?: JSX.Element;
  reverse?: boolean;
  pic: string;
}) {
  return (
    <div
      className="GrowCard"
      style={{
        flexDirection:
          window.innerWidth < 1200
            ? "column"
            : props.reverse
            ? "row-reverse"
            : "row",
      }}
    >
      <div className="pic-part">
        <img alt={"青柚成长"} src={props.pic} />
      </div>
      <div className="content-part">{props.child}</div>
    </div>
  );
}

export default GrowCard;
