import React, { useState } from "react";
import Title from "../Title/Title";
import fe from "../../../../assets/index_part5/fe.png";
import painter from "../../../../assets/index_part5/painter.png";
import be from "../../../../assets/index_part5/be.png";
import operation from "../../../../assets/index_part5/operation.png";
import "./Part5.scss";
interface Part5Prop {
  isShow: boolean;
}
const assets = [
  {
    url: be,
    title: "后端工程师",
    msg: "负责工作室团队项目后端开发工作，跟进研发规范和技术体系建设。",
  },
  {
    url: fe,
    title: "前端工程师",
    msg: "负责工作室团队项目前端开发工作，通过工具与组件的开发与持续优化。",
  },

  {
    url: painter,
    title: "画师&设计师",
    msg: "画师负责完成以“肖辰旭”形象、风格为基础的绘制工作，完成产品界面的部分绘图以及青柚系文创的设计。",
  },
  {
    url: operation,
    title: "产品发行运营",
    msg: "负责青柚工作室的活动策划、新媒体运营和宣传推广工作，加强工作室内部跨团队协作。",
  },
];
function Part5(props: Part5Prop) {
  // const [index, setIndex] = useState(0);
  return (
    <div className="part5-wrap container">
      <div className="content-wrap">
        <div className="xcx-wrap">
          <div className="xcx"></div>
        </div>
        <Title
          title="加入青柚：遇见更好的你"
          detail="用热爱创造无限可能，期待有才华有能力的你"
        ></Title>
        <div className="work-list">
          {assets.map((item, i) => {
            return (
              <div
                key={i}
                className={"list-item list-active-item"}
                // onClick={() => {
                //   setIndex(i);
                // }}
              >
                <div className="img-wrap">
                  <img src={item.url} alt="" />
                </div>
                <div className={"item-content-active"}>
                  <div className="item-indicator"></div>
                  <div className="item-title">{item.title}</div>
                  <div className="item-msg">{item.msg}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="join" onClick={() => window.open('https://forms.njupt.edu.cn/f/EvdwP0')}>加入我们</div>
        {/* <div className="nav-wrap">
          <div className="nav-content">
            立刻加入青柚
            <div className="nav-icon"></div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Part5;
