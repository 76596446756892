import React, {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import throttle from "../../utils/throttle";

import "./Swiper.scss";
interface ContextProp {
  selectedIndex: number;
  setSelectedIndex: Function;
}
interface SwiperProp {
  //用来配置不同样式的指示器
  productIndicatorOptions?: Array<{ iconUrl: string }>;
  indicatorColor?: string;
  swiperBg?: string;
  isMobile?: boolean;
  children: ReactElement | Array<ReactElement>;
  indexControl?: any;
  goTo?: any;
  scrollDistance?: Array<string>;
  haveFooter?: boolean;
}
const swiperContext = React.createContext<ContextProp>({
  selectedIndex: 0,
  setSelectedIndex: () => {},
});
function IndicatorItem(props: {
  onClick: Function;
  selected: boolean;
  bg?: string;
  indicatorColor: string | undefined;
}) {
  const { onClick, selected, bg, indicatorColor } = props;
  return (
    <div
      onClick={onClick as MouseEventHandler<HTMLDivElement>}
      className={
        bg
          ? selected
            ? "product-indicator-item  product-selected-item"
            : "product-indicator-item"
          : selected
          ? "indicator-item selected-item"
          : "indicator-item"
      }
      style={
        indicatorColor
          ? selected
            ? {
                border: `2px solid ${indicatorColor}`,
                backgroundColor: `${indicatorColor}`,
                backgroundImage: `url(${bg})`,
              }
            : {
                backgroundColor: `${indicatorColor}`,
                backgroundImage: `url(${bg})`,
              }
          : { backgroundImage: `url(${bg})` }
      }
    ></div>
  );
}
function Indicator(props: {
  children: ReactElement | Array<ReactElement | null>;
}) {
  return <div className="indicator-wrap">{props.children}</div>;
}
//移动端指示器
function MobileIndicator(props: {
  arrowClick: Function;
  isLast: boolean;
  resetClick: Function;
}) {
  const { arrowClick, isLast, resetClick } = props;
  return isLast ? (
    <div
      onClick={resetClick as MouseEventHandler<HTMLDivElement>}
      className="reset"
    >
      回到首页
    </div>
  ) : (
    <div
      onClick={arrowClick as MouseEventHandler<HTMLDivElement>}
      className={isLast ? "next hide" : "next"}
    ></div>
  );
}
function Swiper(props: SwiperProp) {
  const {
    goTo,
    children,
    isMobile,
    productIndicatorOptions,
    swiperBg,
    indexControl,
    indicatorColor,
    scrollDistance,
    haveFooter,
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsCount = React.Children.count(children);
  const itemsArray = new Array(itemsCount).fill("");
  const canScroll = useRef(true);
  const getTime = useCallback(() => {
    return new Date().getTime();
  }, []);

  const { current } = useRef<any>({
    isScroll: true, // 是否可以正常的滚动
    scrollTime: null, // 上次切换的时间戳（毫秒级别）
    timer: null, // setTimeout句柄
    interval: 500, // 两次滑动之间的最小事件间隔
    distance: 100, // 有效滑动的最小距离
    delay: 400, // 防抖时间
  });
  //适配移动端
  let offsetTop = 0;
  let startTouch = 0;
  const touchStartHandler = (e: any) => {
    offsetTop = e.touches[0].clientY;
    startTouch = Date.now();
  };
  const touchMoveHandler = (e: any) => e.preventDefault();
  const touchEndHandler = (e: any) => {
    const deltaY = e.changedTouches[0].clientY - offsetTop;
    const deltaTime = Date.now() - startTouch;
    if (Math.abs(deltaY) > 50 || (deltaTime < 150 && Math.abs(deltaY) > 50)) {
      setSelectedIndex((lastPositionIndex) => {
        const newPositionIndex = Math.min(
          Math.max(lastPositionIndex + (deltaY <= 0 ? 1 : -1), 0),
          itemsCount - 1
        );
        return newPositionIndex;
      });
    }
  };
  const arrowClickHandler = () => {
    throttle(() => {
      setSelectedIndex((prev) => {
        const newPositionIndex = Math.min(prev + 1, itemsCount - 1);
        return newPositionIndex;
      });
    }, 1000);
  };
  const resetPositionIndex = () => {
    setSelectedIndex(0);
  };
  //注册鼠标滚轮事件处理
  const onWheel = useCallback((e: { deltaY: any }) => {
    const time = getTime();
    const y = e.deltaY; // 以垂直滚动为列

    if (current.scrollTime) {
      // 如果这次触发的时机跟上次切换的时机差大于一定时间，并且滑动距离大于一定距离，那么就认为是有效滑动
      if (
        time - current.scrollTime > current.interval &&
        Math.abs(y) >= current.distance
      ) {
        // 触摸板滚动
        wheelHandler(e);
        clearTimeout(current.timer);
        current.isScroll = false;
        current.scrollTime = time;
      }
    }
    if (current.isScroll) {
      // 正常滚动
      wheelHandler(e);
      current.scrollTime = getTime();
      current.isScroll = false;
    }
    if (current.timer) {
      clearTimeout(current.timer);
    }
    current.timer = setTimeout(() => {
      current.isScroll = true;
    }, current.delay);
  }, []);

  const wheelHandler = (e: { deltaY: number }) => {
    setSelectedIndex((lastPositionIndex) => {
      const deltaY = -e.deltaY;
      const newPositionIndex = Math.min(
        Math.max(lastPositionIndex + (deltaY <= 0 ? 1 : -1), 0),
        itemsCount - 1
      );
      return newPositionIndex;
    });
  };
  const context = useMemo(
    () => ({ selectedIndex, setSelectedIndex }),
    [selectedIndex, setSelectedIndex]
  );
  useEffect(() => {
    const { current } = containerRef;
    if (itemsCount && current) {
      current.addEventListener("touchstart", touchStartHandler);
      current.addEventListener("touchmove", touchMoveHandler);
      current.addEventListener("touchend", touchEndHandler);
      current.addEventListener("wheel", onWheel);
      return () => {
        current.removeEventListener("touchstart", touchStartHandler);
        current.removeEventListener("touchmove", touchMoveHandler);
        current.removeEventListener("touchend", touchEndHandler);
        current.removeEventListener("wheel", onWheel);
      };
    }
  });
  useEffect(() => {
    setTimeout(() => {
      canScroll.current = true;
    }, 500);
    indexControl && indexControl(selectedIndex);
  }, [selectedIndex]);
  useEffect(() => {
    if (goTo) goTo.current = setSelectedIndex;
  }, []);
  return (
    <div
      className="swiper-wrap"
      ref={containerRef}
      style={
        //控制footer不显示背景
        swiperBg && selectedIndex !== 5
          ? {
              backgroundImage: `url(${swiperBg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }
          : {}
      }
    >
      <swiperContext.Provider value={context}>
        <div
          className="swiper-content"
          style={
            !scrollDistance
              ? {
                  transform: `translate3d(0px, -${100 * selectedIndex}vh, 0px)`,
                }
              : {
                  transform: `translate3d(0px, -${scrollDistance[selectedIndex]}, 0px)`,
                }
          }
        >
          {React.Children.map(children, (child) => {
            return React.cloneElement(child);
          })}
        </div>
        {isMobile ? (
          <MobileIndicator
            isLast={itemsCount - 1 === selectedIndex}
            resetClick={resetPositionIndex}
            arrowClick={arrowClickHandler}
          ></MobileIndicator>
        ) : (
          <Indicator>
            {itemsArray.map((_, index) => {
              return haveFooter && index === itemsArray.length - 1 ? null : (
                <IndicatorItem
                  key={index}
                  onClick={() => {
                    setSelectedIndex(index);
                  }}
                  selected={index === context.selectedIndex}
                  bg={
                    productIndicatorOptions &&
                    productIndicatorOptions[index].iconUrl
                  }
                  indicatorColor={indicatorColor}
                ></IndicatorItem>
              );
            })}
          </Indicator>
        )}
      </swiperContext.Provider>
    </div>
  );
}

export default Swiper;
