import React from "react";

import "./index.scss";

function Title(props: { icon: string; title: string; subtitle: string }) {
  return (
    <div className="Title">
      <div
        className="title-icon"
        style={{ backgroundImage: `url(${props.icon})` }}
      />
      <div className="title">{props.title}</div>
      <div className="subtitle">{props.subtitle}</div>
    </div>
  );
}

export default Title;
