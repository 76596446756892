import React from "react";
import Title from "../Title";
import GrowCard from "./GrowCard";

import "./index.scss";

import grow from "../../../../assets/people/grow.svg";

import growpic1 from "../../../../assets/people/grow-pic1.png";
import growpic2 from "../../../../assets/people/grow-pic2.png";

function GrowMobile() {
  return (
    <div className="Grow">
      <div className="grow-inner">
        <Title icon={grow} title={"青柚成长"} subtitle={"Grow in QingYou"} />
        <GrowCard
          reverse
          child={
            <>
              <div className="card-title">青柚在日益成长</div>
              <div className="card-content">
                从一开始的几人团队到现在五十多人的工作室，青柚工作室吸引并欢迎越来越多志同道合的朋友们加入！我们和肖辰旭一起成长，一起进步，一起变得强大！
              </div>
              <div className="card-data-container">
                <div className="card-data">
                  <div className="data-text">
                    <p>93</p>
                    <p>人</p>
                  </div>
                  <div className="data-label">工作室成员数</div>
                </div>
                <div className="card-space" />
                <div className="card-data">
                  <div className="data-text">
                    <p>36</p>
                    <p>人</p>
                  </div>
                  <div className="data-label">毕业成员数</div>
                </div>
                <div className="card-space" />
              </div>
            </>
          }
          pic={growpic1}
        />
        <GrowCard
          child={
            <>
              <div className="card-title">做成长路上的得力队友</div>
              <div className="card-content">
                在这个团队里，大家讨论、交流、学习、进步、共事...在这里，你可以写你感兴趣的项目，为期末报告单设计UI，策划你喜欢的用户交流活动。平时再水水群、聊聊天，如果恰好赶上了秋招还能蹭上学长学姐们一顿又一顿的offer饭。
                成长和进步是一局团战，要和得力的队友一起打！
              </div>
            </>
          }
          pic={growpic2}
        />
      </div>
    </div>
  );
}

export default GrowMobile;
