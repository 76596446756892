import React, { Fragment, useState } from "react";
import Header from "../../components/Header/Header";
import Swiper from "../../components/Swiper/Swiper";
import ProductPart from "./components/ProductPart/ProductPart";

import youkexingBackground from "../../assets/products/background/youkexing.png";
import youkexingCode from "../../assets/products/code/youkexing.png";
import youkexingIcon from "../../assets/products/icon/youkexing.png";
import youkexingMock from "../../assets/products/mock/youkexing.png";
import younilaibanBackground from "../../assets/products/background/younilaiban.png";
import younilaibanIcon from "../../assets/products/icon/younilaiban.png";
import younilaibanMock from "../../assets/products/mock/younilaiban.png";
import nanyouxiaochengxuBackground from "../../assets/products/background/nanyouxiaochengxu.png";
import nanyouxiaochengxuIcon from "../../assets/products/icon/nanyouxiaochengxu.png";
import nanyouxiaochengxuMock from "../../assets/products/mock/nanyouxiaochengxu.png";
import biyezhao2021Background from "../../assets/products/background/biyezhao2021.png";
import biyezhao2021Icon from "../../assets/products/icon/biyezhao2021.png";
import biyezhao2021Mock from "../../assets/products/mock/biyezhao2021.png";
import nitouxiangzhenbangBackground from "../../assets/products/background/nitouxiangzhenbang.png";
import nitouxiangzhenbangIcon from "../../assets/products/icon/nitouxiangzhenbang.png";
import nitouxiangzhenbangMock from "../../assets/products/mock/nitouxiangzhenbang.png";
import qimobaogaodanBackground from "../../assets/products/background/qimobaogaodan.png";
import qimobaogaodanIcon from "../../assets/products/icon/qimobaogaodan.png";
import qimobaogaodanMock from "../../assets/products/mock/qimobaogaodan.png";
import nanyoujingxiangzhanBackground from "../../assets/products/background/nanyoujingxiangzhan.png";
import nanyoujingxiangzhanIcon from "../../assets/products/icon/nanyoujingxiangzhan.png";
import nanyoujingxiangzhanMock from "../../assets/products/mock/nanyoujingxiangzhan.png";
import ifitBg from "../../assets/products/background/ifit.png";
import ifitIcon from "../../assets/products/icon/ifit.png";
import ifitMock from "../../assets/products/mock/ifit.png";
import tiyuchangguanBg from "../../assets/products/background/tiyuchangguanyuyue.png";
import tiyuchangguanIcon from "../../assets/products/icon/tiyuchangguanyuyue.png";
import tiyuchangguanMock from "../../assets/products/mock/tiyuchangguanyuyue.png";
import webplusBg from "../../assets/products/background/webplus.png";
import webplusIcon from "../../assets/products/icon/webplus.png";
import webplusMock from "../../assets/products/mock/webplus.png";
import jiankangdakaBg from "../../assets/products/background/qiyeweixin.png";
import jiankangdakaIcon from "../../assets/products/icon/qiyeweixin.png";
import jiankangdakaMock from "../../assets/products/mock/qiyeweixin.png";
import qingyouwenchuangBg from "../../assets/products/background/qingyouwenchuang.png";
import qingyouwenchuangIcon from "../../assets/products/icon/qingyouwenchuang.png";
import qingyouwenchuangMock from "../../assets/products/mock/qingyouwenchuang.png";
import younilaibanCode from "../../assets/products/code/younilaiban.png";
import nanyouxiaochengxuCode from "../../assets/products/code/nanyouxiaochengxu.png";
import biyezhao2021Code from "../../assets/products/code/biyezhao2021.png";
import nitouxiangzhenbangCode from "../../assets/products/code/nitouxiangzhenbang.png";
import tiyuchangguanCode from "../../assets/products/code/tiyuchangguanyuyue.png";

const products = [
  {
    iconUrl: youkexingIcon,
    title: "邮客行",
    detail:
      "“邮客行｜南邮访客登记”小程序是一款线上入校访客登记系统。疫情防控形势较为严峻复杂，来校访客登记需纳入高校常规化管理范畴。“邮客行”联合企业微信和微信两个平台，实现了入校访客在微信端申请，校内管理人员在企业微信端审批的功能。",
    bgUrl: youkexingBackground,
    mockUrl: youkexingMock,
    qrCode: youkexingCode,
    bgColor: "rgba(246, 252, 255, 0.96)",
  },
  {
    iconUrl: younilaibanIcon,
    title: "邮你来办",
    detail:
      "“邮你来办”小程序是一款为南邮师生量身打造的出入校审批系统。在疫情防控常态化的当下，“邮你来办”小程序简化出入校手续申请流程，清晰呈现了手续申请的不同审批状态，极大地提高了全校师生出入校手续申请的效率。",
    bgUrl: younilaibanBackground,
    mockUrl: younilaibanMock,
    qrCode: younilaibanCode,
    bgColor: "rgba(240, 243, 238, 0.91)",
  },
  {
    iconUrl: nanyouxiaochengxuIcon,
    title: "南邮小程序",
    detail:
      "南京邮电大学小程序是一款方便快捷的校园小程序，具有学生校园生活所需的众多便捷功能，其广泛服务于学生的日常学习生活。南邮小程序集学生个人信息查询、专业课表查询、跑操次数记录、图书馆功能、一卡通功能、校内外资讯等诸多功能于一身，真正地做到信息的便捷化智能管理，方便柚子们的日常学习生活。",
    bgUrl: nanyouxiaochengxuBackground,
    mockUrl: nanyouxiaochengxuMock,
    qrCode: nanyouxiaochengxuCode,
    bgColor: "rgba(246, 249, 255, 0.91)",
  },
  {
    iconUrl: biyezhao2021Icon,
    title: "毕业照 2020",
    detail:
      "“毕业照2020”小程序是一款线上云毕业照拍摄系统，集功能性和社交性于一体，解决了疫情防控期间部分同学无法实地拍摄毕业照的问题。“毕业照2020”小程序可自动识别人脸，更有服装、背景和挂件等多种元素任君挑选。",
    bgUrl: biyezhao2021Background,
    mockUrl: biyezhao2021Mock,
    qrCode: biyezhao2021Code,
    bgColor: "rgba(238, 241, 255, 0.91)",
  },
  {
    iconUrl: nitouxiangzhenbangIcon,
    title: "你头像真棒",
    detail:
      "“你头像真棒”小程序是一款以节日和南邮校庆为主题的专属头像框生成系统，为同学们提供了海量炫酷头像框素材，柚子们可以通过小程序上传图片，自主挑选南邮主题头像框，一键就能get专属自己的南邮头像。",
    bgUrl: nitouxiangzhenbangBackground,
    mockUrl: nitouxiangzhenbangMock,
    qrCode: nitouxiangzhenbangCode,
    bgColor: "rgba(247, 249, 244, 0.91)",
  },
  {
    iconUrl: qimobaogaodanIcon,
    title: "本科生期末报告单",
    detail:
      "“本科生期末报告单”是一款生成在校本科生每学期学习生活情况的系统。整合该学期柚子们的学习、娱乐、社交等状态于一体，生成学期报告。柚子们可以从学业、成绩、宿舍、跑操、离校等角度多方面评估本学期自己的学习生活情况，及时对镜自省。",
    bgUrl: qimobaogaodanBackground,
    mockUrl: qimobaogaodanMock,
    bgColor: "rgba(207, 236, 230, 0.91)",
  },
  {
    iconUrl: nanyoujingxiangzhanIcon,
    title: "南邮镜像站",
    detail:
      "“南邮镜像站”由南京邮电大学信息化建设和管理办公室提供支持创办、由南京邮电大学NMG小组运行维护。南邮镜像站可以实现分流，起到减少服务器压力的作用，从而做到镜像分发加速等需求。",
    bgUrl: nanyoujingxiangzhanBackground,
    mockUrl: nanyoujingxiangzhanMock,
    bgColor: "rgba(207, 236, 230, 0.91)",
  },
  {
    iconUrl: ifitIcon,
    title: "IFIT体育成绩系统",
    detail:
      "“IFIT体育系统”是一款查询本科生体育测试成绩与体育课成绩的信息管理系统。“体育系统”拥有移动端与网页端两种查询方式，便于柚子们快捷的查询自己每学期的在校体育成绩，随时掌握自己的体育成绩的综合情况。",
    bgUrl: ifitBg,
    mockUrl: ifitMock,
    bgColor: "rgba(207, 236, 230, 0.91)",
  },
  {
    iconUrl: tiyuchangguanIcon,
    title: "体育馆预约",
    detail:
      "“体育馆预约”是一款服务于南邮师生的体育馆场位预约系统，能够做到根据预约为南邮师生分配管理体育馆场所，避免在场地使用上产生纠纷，不断地满足着柚子们多样化的使用需求。",
    bgUrl: tiyuchangguanBg,
    mockUrl: tiyuchangguanMock,
    qrCode: tiyuchangguanCode,
    bgColor: "rgba(207, 236, 230, 0.91)",
  },
  {
    iconUrl: webplusIcon,
    title: "WEBPLUS门户网站",
    detail:
      "WEBPLUS 站群由青柚前后端工程师开发维护，便于南邮师生了解各个部门最新的通知公告与新闻资讯，让同学们能够在第一时间掌握科研信息，为广大南邮学子提供了一个学术交流平台。",
    bgUrl: webplusBg,
    mockUrl: webplusMock,
    bgColor: "rgba(207, 236, 230, 0.91)",
  },
  {
    iconUrl: jiankangdakaIcon,
    title: "企业微信健康打卡",
    detail:
      "“企业微信健康打卡”是一款服务于南邮师生的每日健康状况汇报系统。在疫情形势严峻、防控形式严格的当下，“企业微信健康打卡”将实时上传地理位置、每日打卡提醒、健康状况评估等多种功能融为一体，实施便捷化信息管理，推动南邮常态化疫情防控管理工作的有序发展。",
    bgUrl: jiankangdakaBg,
    mockUrl: jiankangdakaMock,
    bgColor: "rgba(207, 236, 230, 0.91)",
  },
  {
    iconUrl: qingyouwenchuangIcon,
    title: "青柚文创",
    detail:
      "“青柚文创”是青柚工作室自主推出的校园文创系列产品。青柚画师们根据青柚工作室LOGO、南邮校园文化以及南邮特色风景设计出一系列带有浓厚校园气息的文创产品，展示南邮文化的丰富内涵。",
    bgUrl: qingyouwenchuangBg,
    mockUrl: qingyouwenchuangMock,
    bgColor: "rgba(207, 236, 230, 0.91)",
  },
];

interface ProductProp {
  isMobile: boolean;
}
function Product(props: ProductProp) {
  const { isMobile } = props;
  const [index, setIndex] = useState(0);
  const handleIndex = (currentIndex: number) => {
    setIndex(currentIndex);
  };
  return (
    <Fragment>
      <Header
        isLinear={true}
        mode="completion"
        isTransparent={true}
        bgColor={products[index].bgColor}
      ></Header>
      <div className="product-body" style={{ width: "100vw" }}>
        {isMobile ? (
          <Swiper isMobile={true}>
            {products.map((value, index) => {
              return (
                <ProductPart
                  key={index}
                  iconUrl={value.iconUrl}
                  bgUrl={value.bgUrl}
                  title={value.title}
                  detail={value.detail}
                  mock={value.mockUrl}
                  qrCode={value.qrCode}
                ></ProductPart>
              );
            })}
          </Swiper>
        ) : (
          <Swiper productIndicatorOptions={products} indexControl={handleIndex}>
            {products.map((value, index) => {
              return (
                <ProductPart
                  key={index}
                  iconUrl={value.iconUrl}
                  bgUrl={value.bgUrl}
                  title={value.title}
                  detail={value.detail}
                  mock={value.mockUrl}
                  qrCode={value.qrCode}
                ></ProductPart>
              );
            })}
          </Swiper>
        )}
      </div>
    </Fragment>
  );
}

export default Product;
