import "./Header.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { findTextColor } from "../../utils/textcolor";
import qy_icon from "../../assets/qy-logo.png";

interface HeaderProp {
  isTransparent?: boolean;
  bgColor?: string;
  isLinear?: boolean;
  isDark?: boolean;
  notShow?: boolean;
  mode?: string;
}

function Header(props: HeaderProp) {
  const { isTransparent, bgColor, isLinear, isDark, notShow } = props;
  const [dynamicBgColor, setDynamicBgColor] = useState(
    "rgba(255, 255, 255, 0)"
  );
  let location = useLocation();
  const [bannerSelect, setBannerSel] = useState(0);
  const [showMobile, setShowMobile] = useState(false);
  const navigateTo = useNavigate();
  useEffect(() => {
    // console.log(props.bgColor)
    setTimeout(() => {
      setDynamicBgColor(`${props.bgColor}`);
    }, 250);
  }, [props.bgColor]);
  const headerBackgroundStyle = () => {
    if (isTransparent && bgColor) {
      return { backgroundColor: `rgba(255, 255, 255, 0)` };
    } else if (isLinear && bgColor) {
      return {
        background: `linear-gradient(180deg, ${dynamicBgColor} 73.96%, rgba(255, 255, 255, 0) 100%)`,
      };
    } else {
      return {};
    }
  };
  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setBannerSel(0);
        break;
      case "/product":
        setBannerSel(1);
        break;
      case "/people":
        setBannerSel(2);
        break;
      case "/about":
        setBannerSel(4);
        break;
      default:
        break;
    }
  }, [location]);
  const bannerAssets = [
    {
      name: "首页",
      path: "/",
    },
    {
      name: "青柚智造",
      path: "/product",
    },
    {
      name: "青柚少年",
      path: "/people",
    },
    {
      name: "青柚故事",
      path: "/blog",
    },
    {
      name: "关于我们",
      path: "/about",
    },
  ];
  return (
    <>
      <div
        className="roof"
        style={
          showMobile
            ? isDark
              ? {
                  visibility: "visible",
                  backgroundColor: "rgba(12, 13, 26, 0.97)",
                }
              : {
                  visibility: "visible",
                  backgroundColor: "rgba(255,255, 255, 0.97)",
                }
            : { visibility: "hidden" }
        }
      >
        <ul>
          {bannerAssets.map((value, index) => {
            return (
              <li
                key={index}
                className="banner-item"
                style={
                  index === bannerSelect
                    ? isDark
                      ? { color: "white", opacity: 1 }
                      : { color: "black", opacity: 1 }
                    : isDark
                    ? { color: "white" }
                    : { color: "black" }
                }
                onClick={() => {
                  if (index !== 3) {
                    setBannerSel(index);
                    navigateTo(value.path);
                    setShowMobile(false);
                  } else {
                    setBannerSel(index);
                    setShowMobile(false);
                    let domain = document.domain;
                    let protocol = document.location.protocol;
                    let port = document.location.port;
                    window.location.href = `${protocol}//${domain}${port}/blog`;
                  }
                }}
              >
                {value.name}
              </li>
            );
          })}
        </ul>
      </div>
      <div style={headerBackgroundStyle()} className="header-wrap">
        <div
          className={
            showMobile
              ? isDark
                ? "show-mobile-menu header-body dark-header-body"
                : "show-mobile-menu header-body"
              : isDark
              ? "header-body dark-header-body"
              : "header-body"
          }
          style={notShow ? { opacity: 0 } : { opacity: 1 }}
        >
          <div
            className="header-icon"
            style={isDark ? { backgroundImage: `url(${qy_icon})` } : {}}
          ></div>
          <div
            className="header-banner"
            style={{ color: `${findTextColor(dynamicBgColor)}` }}
          >
            {bannerAssets.map((value, index) => {
              return (
                <div className="banner-item-wrap" key={index}>
                  <div
                    className="banner-item"
                    style={
                      index === bannerSelect
                        ? isDark
                          ? { color: "white", opacity: 1 }
                          : { color: "black", opacity: 1 }
                        : isDark
                        ? { color: "white" }
                        : { color: "black" }
                    }
                    onClick={() => {
                      if (index !== 3) {
                        setBannerSel(index);
                        navigateTo(value.path);
                      } else {
                        setBannerSel(index);
                        let domain = document.domain;
                        let protocol = document.location.protocol;
                        let port = document.location.port;
                        window.location.href = `${protocol}//${domain}${port}/blog`;
                      }
                    }}
                  >
                    {value.name}
                  </div>
                  {index === bannerSelect ? (
                    <div
                      className="banner-divide"
                      style={
                        isDark
                          ? { backgroundColor: "white" }
                          : { backgroundColor: "black" }
                      }
                    ></div>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div
            className="btn-wrap"
            onClick={() => {
              setShowMobile((prev) => {
                return !prev;
              });
            }}
          >
            <a href="javascript:;" className="btn-menu"></a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
