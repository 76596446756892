import React, { useState } from "react"
import { Carousel } from "antd"
import Title from "../Title/Title"
import { FadeInDown } from "../../../../components/Animation/Animation"
import photo1 from "../../../../assets/index_part3/group_photo1.jpeg"
import photo2 from "../../../../assets/index_part3/group_photo2.jpeg"
import photo3 from "../../../../assets/index_part3/group_photo3.jpeg"
import photo4 from "../../../../assets/index_part3/group_photo4.jpeg"
import photo5 from "../../../../assets/index_part3/group_photo5.jpeg"
import photo6 from "../../../../assets/index_part3/group_photo6.jpeg"




import PersonNum from "../../../../components/PersonNum/PersonNum"
import "./Part3.scss"
import { useNavigate } from "react-router-dom"
interface Part3Prop {
  isShow: boolean
}
const assets = [photo1, photo2, photo3, photo4, photo5, photo6]
function Part3(props: Part3Prop) {
  const navigate = useNavigate()
  return (
    <div className="part3-wrap container">
      <FadeInDown isShow={props.isShow}>
        <div className="content-wrap">
          <div className="qxy-wrap">
            <div className="qxy"></div>
          </div>
          <Title
            title="青柚少年：成长是一场团战"
            detail="世代里的每个高光时刻，都由无数坚守的身影所铸就"
          ></Title>
          <div className="content">
            <div className="carousel-wrap" style={{ overflow: "hidden" }}>
              <Carousel
                autoplay
                style={{ overflow: "hidden" }}
                className="carousel"
              >
                {assets.map((url, index) => {
                  return (
                    <div className="img-wrap" key={index}>
                      <div
                        className="img"
                        key={index}
                        style={{ backgroundImage: `url(${url})` }}
                      />
                    </div>
                  )
                })}
              </Carousel>
            </div>
            <div className="right-wrap">
              <div className="title">个人发展</div>
              <div className="slg">做彼此的得力队友。</div>
              <div className="slg-content">
                在这个团队里，大家讨论、交流、学习、进步、共事...写你感兴趣的项目，为期末报告单设计UI，策划你喜欢的用户交流活动，平时再水水群、聊聊天，如果恰好赶上了秋招还能蹭上学长学姐们一顿又一顿的offer饭。
              </div>
              <div className="people">
                <PersonNum
                  personNum="93"
                  tagContent="工作室成员数"
                  style={{
                    height: '116px',
                    marginRight: '61px'
                  }}
                  numStyle={{
                    color: '#92d241',
                    fontSize: '75px',
                    lineHeight: '55px'
                  }}
                  renStyle={{
                    fontSize: '28px',
                    fontWeight: 600
                  }}
                  tagStyle={{
                    color: '#6ba32ae6',
                    fontSize: '29px',
                    lineHeight: '32px',
                    fontWeight: 600,
                    letterSpacing: 5,
                  }}
                />
                <PersonNum
                  personNum="36"
                  tagContent="毕业成员数"
                  style={{
                    height: '116px',
                  }}
                  numStyle={{
                    color: '#92d241',
                    fontSize: '75px',
                    lineHeight: '55px'
                  }}
                  renStyle={{
                    fontSize: '28px',
                    fontWeight: 600
                  }}
                  tagStyle={{
                    color: '#6ba32ae6',
                    fontSize: '29px',
                    lineHeight: '32px',
                    fontWeight: 600,
                    letterSpacing: 5,
                  }}
                />
              </div>
              <div className="nav-wrap">
                <div
                  className="nav-content"
                  onClick={() => {
                    navigate("/people")
                  }}
                >
                  走近青柚少年的世界
                  <div className="nav-icon"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInDown>
    </div>
  )
}

export default Part3
