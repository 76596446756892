import React from "react";
import Title from "../Title/Title";
import "./MobilePart3.scss";
import { useNavigate } from "react-router-dom";

function MobilePart3() {
  const navigate = useNavigate();
  return (
    <div className="mobile-part3-wrap mobile-container">
      <div className="mobile-part3-container">
        <Title
          title="青柚少年：成长是一场团战"
          detail="世代里的每个高光时刻，都由无数坚守的身影所铸就"
        ></Title>
        <div className="carousel-wrap"></div>
        <div className="bottom-wrap">
          <div className="bottom-content">
            <div className="title">个人发展</div>
            <div className="slg">做彼此的得力队友。</div>
            <div className="slg-content">
              在这个团队里，大家讨论、交流、学习、进步、共事...写你感兴趣的项目，为期末报告单设计UI，策划你喜欢的用户交流活动，平时再水水群、聊聊天，如果恰好赶上了秋招还能蹭上学长学姐们一顿又一顿的offer饭。
            </div>
            <div className="people">
              <div className="all"></div>
              <div className="graduate"></div>
            </div>
          </div>
        </div>
        <div className="nav-wrap">
          <div
            className="nav-content"
            onClick={() => {
              navigate("/people");
            }}
          >
            走近青柚少年
            <div className="nav-icon"></div>
          </div>
        </div>
        <div className="qxy-wrap"></div>
      </div>
    </div>
  );
}

export default MobilePart3;
