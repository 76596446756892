import React, { useEffect } from "react";
import Title from "../Title/Title";
import "./MobilePart2.scss";
import mobile from "../../../../assets/index_part2/mobile-pro.png";
import { useNavigate } from "react-router-dom";
import Scrollbar from "smooth-scrollbar";

function MobilePart2() {
  useEffect(() => {
    const scroll = Scrollbar.init(
      document.querySelector(".mobile-wrap") as HTMLElement,
      {}
    );
    scroll.scrollLeft =
      (document.querySelector(".mobile-content")?.clientWidth as number) * 0.35;
  });
  const navigate = useNavigate();
  return (
    <div className="mobile-part2-wrap mobile-container">
      <div className="mobile-part2-container">
        <Title
          title="青柚智造：做自己喜欢的产品"
          detail="日夜点滴坚守的力量与汗水，让每个青春瞬间精彩绽放"
        ></Title>
        <div className="mobile-wrap">
          <img src={mobile} className="mobile-content" alt=""></img>
        </div>
        <div className="nav-wrap">
          <div
            className="nav-content"
            onClick={() => {
              navigate("/product");
            }}
          >
            了解更多智造
            <div className="nav-icon"></div>
          </div>
        </div>
        <div className="xcx-wrap"></div>
      </div>
    </div>
  );
}

export default MobilePart2;
