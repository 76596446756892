import React, { useEffect, useState } from "react";
import "./index.scss";

function Cover() {
  const handleNext = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };
  return (
    <div className="Cover">
      <div className="cover-bg">
        <div className="bg-inner">
          <div className="back-circle">
            <div className="circle-item" />
            <div className="circle-item" />
            <div className="circle-item" />
          </div>
        </div>
      </div>
      <div className="cover-content">
        <div className="people-title">青柚人</div>
        <div className="people-subtitle">OUR TEAM</div>
        <div className="slogan-en">Grow Together</div>
        <div className="slogan-zh">成长是一局团战</div>
        <div className="next-arrow" onClick={handleNext} />
      </div>
    </div>
  );
}

export default Cover;
