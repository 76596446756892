import React from "react";
import { useNavigate } from "react-router-dom";
import Title from "../Title/Title";
import { Down } from "../../../../components/Animation/Animation";
import "./Part2.scss";
import graduationPhoto from "../../../../assets/index_part2/graduate.png";
import njuptPhoto from "../../../../assets/index_part2/njupt.png";
import commemoratePhoto from "../../../../assets/index_part2/head.png";
import visitorPhoto from "../../../../assets/index_part2/visitor.png";
import freegatePhoto from "../../../../assets/index_part2/freegate.png";
interface Part2Prop {
  isShow: boolean;
}
function Part2(props: Part2Prop) {
  const navigate = useNavigate();
  return (
    <div className="part2-wrap container">
      <div className="content-wrap">
        <div className="xcx-wrap">
          <div className="xcx"></div>
        </div>
        <Title
          title="青柚智造：做自己喜欢的产品"
          detail="日夜点滴坚守的力量与汗水，让每个青春瞬间精彩绽放"
        ></Title>
        <div className="mobile-prototype">
          <Down isShow={props.isShow}>
            <div className="mobile-item mobile1">
              <img src={njuptPhoto} alt="" className="mobile" />
            </div>
          </Down>
          <Down isShow={props.isShow}>
            <div className="mobile-item mobile2">
              <img src={visitorPhoto} alt="" className="mobile" />
            </div>
          </Down>
          <Down isShow={props.isShow}>
            <div className="mobile-item mobile3">
              <img src={graduationPhoto} alt="" className="mobile" />
            </div>
          </Down>
          <Down isShow={props.isShow}>
            <div className="mobile-item mobile4">
              <img src={commemoratePhoto} alt="" className="mobile" />
            </div>
          </Down>
          <Down isShow={props.isShow}>
            <div className="mobile-item mobile5">
              <img src={freegatePhoto} alt="" className="mobile" />
            </div>
          </Down>
        </div>
        <div className="nav-wrap">
          <div
            className="nav-content"
            onClick={() => {
              navigate("/product");
            }}
          >
            了解更多青柚产品与创造
            <div className="nav-icon"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Part2;
