import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { membersList } from "../people/components/Members/members";

import "./index.scss";
import user from "../../assets/about/1.png";
import team from "../../assets/about/2.png";
import project from "../../assets/about/3.png";
import goal from "../../assets/about/t4.33240da3.png";
import growth from "../../assets/about/t3.e9f1daf5.png";
import mobile1 from "../../assets/about/xcx.1.png";
import mobile2 from "../../assets/about/xcx.2.png";
import mobile3 from "../../assets/about/xcx.3.png";
import mobile4 from "../../assets/about/xcx.4.png";
import mobile5 from "../../assets/about/xcx.5.png";
import leftMascot from "../../assets/about/x.5838b947.png";
import rightMascot from "../../assets/about/q.35261676.png";
import topAutoImg from "../../assets/about/t2.6ee9d0a1.png";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import PersonNum from "../../components/PersonNum/PersonNum";

export default function About() {
  const navigateTo = useNavigate();

  // 宽度和滚动高度
  const [clientWidth, setClientWidth] = useState(0);
  const [afterScrollTop, setAfterScrollTop] = useState(0);

  // 定义青柚人肖像数组
  const [people, sortPeople] = useState(
    membersList.filter(
      (item) =>
        item.grade !== "指导老师" &&
        item.grade !== "负责人" &&
        item.avatar !==
        "https://wechat.static.qingyou.ren/qingyou-website/xcx.jpg" &&
        item.avatar !==
        "https://wechat.static.qingyou.ren/qingyou-website/qxy.jpg"
    )
  );

  const sortHandler = () => {
    sortPeople([...[...people].sort(() => Math.random() - 0.5)]);
  };

  const [headerDark, setHeaderDark] = useState(
    !(window.scrollY > window.innerHeight * 0.9)
  );
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop;
      const newDark = !(window.scrollY > window.innerHeight * 0.9);
      setHeaderDark((prev: boolean) => {
        if ((!newDark && prev) || (newDark && !prev)) {
          return !(window.scrollY > window.innerHeight * 0.9);
        } else return prev;
      });
      setAfterScrollTop(scrollTop);
      setClientWidth(document.body.clientWidth);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  // Section 简介
  let introduce = [
    `2017年春天，南邮小程序上显示出第一句"Hello, world"，
    今天，我们有超过7万用户，超过1.3万日活，<br />超过10万日访问量，超过2000万次接口调用。
    我们和肖辰旭一起成长，一起进步，一起变得强大，
    却从未忘却初心。
    两年，继续前进！`,
    `南邮小程序、校谈、期末报告单、社团招新系统、你头像真棒等等，
    我们既是这些产品的开发者，也是他们最忠实的用户，更是最能挑刺的人。
    做自己会用的产品，做自己也喜欢用的产品，
    用心，做让自己心满意足的事。`,
    `从一开始的几人团队到现在五十多人的工作室，青柚工作室吸引并欢迎越来越多志同道合的朋友们加入。
    在这个团队里，大家讨论、交流、学习、进步、共事...写你感兴趣的项目，为期末报告单设计UI，策划你喜欢的用户交流活动，平时再水水群、聊聊天，如果恰好赶上了秋招还能蹭上学长学姐们一顿又一顿的offer饭。
    成长和进步是一局团战，要和得力的队友一起打！`,
    `青柚人的目的是什么?
    看到南邮小程序99.99%的覆盖率，看到期末报告单在朋友圈里刷屏，
    看到同学们在表白墙吐槽我们的功能不够完善，看到有人向朋友炫耀南邮的信息化。
    我们不愿主动追求物质上的奖励，
    仅希望用技术服务更多人，用以取悦自己。`,
  ];

  // 让数值维持在一个范围之内
  const rangeHandler = (min: number, max: number, raw: number) => {
    return Math.min(max, Math.max(min, raw));
  };

  // 顶部动画效果
  const offsetHeader = rangeHandler(0, 798, afterScrollTop);
  let rightDistance =
    clientWidth <= 920 || offsetHeader === 0
      ? 40
      : offsetHeader >= 798
        ? 0
        : Math.max((1 - offsetHeader / 798) * 40, 0);

  // 产品动画效果
  const offsetProdcut = rangeHandler(570, 750, afterScrollTop);
  let base,
    noneBase = clientWidth <= 920,
    topDistance =
      (base = noneBase ? 0 : Math.max(0, 0.4 * (750 - offsetProdcut))) > 36
        ? 2 * (72 - base)
        : 2 * base;

  // 人物动画效果 (2500 - 1700) = 420 * ?
  const offsetGoal = rangeHandler(1750, 2600, afterScrollTop);
  let flyDistance = clientWidth > 920 ? ((offsetGoal - 1750) * 42) / 80 : 0;

  return (
    <main>
      <Header
        mode="completion"
        isLinear={true}
        isTransparent={headerDark}
        bgColor={"#ffffff"}
      ></Header>
      <section className="part">
        <div className="contanier">
          <div className="title" />
          <div className="content-wrap">
            {introduce[0].split("\n").map((item, index) => {
              return (
                <p
                  className="content"
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item.trim() }}
                />
              );
            })}
          </div>
        </div>
        <div
          className="autoimg"
          style={{
            transform: `translateX(${rightDistance}vw)`,
          }}
        ></div>
      </section>
      <section className="part">
        <div className="contanier">
          <img alt="" className="title" src={topAutoImg} />
          <div className="content-wrap">
            {introduce[1].split("\n").map((item, index) => {
              return (
                <p
                  className="content"
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item.trim() }}
                />
              );
            })}
          </div>
          <span className="more" onClick={() => navigateTo("/product")}>
            了解我们的更多产品 &gt;{" "}
          </span>
          <div className="product">
            <img
              alt=""
              className="product-item"
              src={mobile1}
              style={{
                zIndex: 5,
              }}
            />
            <img
              alt=""
              className="product-item"
              src={mobile2}
              style={{
                zIndex: 4,
                marginTop: `${noneBase ? 64 : base}px`,
              }}
            />
            <img
              alt=""
              className="product-item"
              src={mobile3}
              style={{
                zIndex: 3,
                marginTop: `${noneBase ? -48 : topDistance}px`,
              }}
            />
            <img
              alt=""
              className="product-item"
              src={mobile4}
              style={{
                zIndex: 2,
                marginTop: `${noneBase ? 16 : 0}px`,
              }}
            />
            <img
              alt=""
              className="product-item"
              src={mobile5}
              style={{
                zIndex: 1,
                marginTop: `${base}px`,
              }}
            />
          </div>
        </div>
      </section>
      <section className="part">
        <div className="contanier">
          <div className="left-content">
            <img alt="" className="title" src={growth} />
            <div className="content-wrap">
              {introduce[2].split("\n").map((item, index) => {
                return (
                  <p
                    className="content"
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item.trim() }}
                  />
                );
              })}
            </div>
            <div className="persons">
              <PersonNum
                personNum="93"
                tagContent="工作室成员数"
                tagStyle={{
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              />
              <PersonNum
                personNum="36"
                tagContent="毕业成员数"
                tagStyle={{
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              />
            </div>
            <div
              className="join"
              onClick={() => window.open("https://forms.njupt.edu.cn/f/EvdwP0")}
            >
              加入青柚
            </div>
          </div>
          <div className="right-people">
            <div className="people-wall">
              {people.map((item, index) => {
                if (index >= 21) return "";

                return (
                  <img
                    alt=""
                    className="person"
                    src={item.avatar}
                    key={item.name}
                  />
                );
              })}
            </div>
            <div className="people-sort" onClick={sortHandler}>
              随机换一拨
            </div>
          </div>
        </div>
      </section>
      <section className="part">
        <img
          alt=""
          className="mascot"
          src={leftMascot}
          style={{
            transform: `translate(-35vw, -${flyDistance}px)`,
          }}
        />
        <img
          alt=""
          className="mascot"
          src={rightMascot}
          style={{
            transform: `translate(35vw, -${flyDistance}px)`,
          }}
        />
        <div className="contanier">
          <img alt="" className="title" src={goal} />
          <div className="content-wrap">
            {introduce[3].split("\n").map((item, index) => {
              return (
                <p
                  className="content"
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item.trim() }}
                />
              );
            })}
          </div>
          <div className="more" onClick={() => navigateTo("/people")}>
            了解青柚团队 &gt;
          </div>
          <div className="card-wrap">
            <div className="card">
              <img alt="" className="img" src={user} />
              <p className="sub">用户原则</p>
            </div>
            <div className="card">
              <img alt="" className="img" src={team} />
              <p className="sub">团队共识</p>
            </div>
            <div className="card">
              <img alt="" className="img" src={project} />
              <p className="sub">项目目的</p>
            </div>
          </div>
          {/*<div className="divider" />*/
          /*<div className="source">*/
          /*  <div className="left">*/
          /*    <img alt="" className="img" src={greenPomelo} />*/
          /*    <div className="text">*/
          /*      <p>开放和共享是一种学习。</p>*/
          /*      <p>访问青柚的 GitHub 仓库 &gt;</p>*/
          /*    </div>*/
          /*  </div>*/
          /*  <img alt="" className="right" src={source} />*/
          /*</div>*/}
        </div>
      </section>
      <Footer></Footer>
    </main>
  );
}
