import Header from "../../components/Header/Header";
import Swiper from "../../components/Swiper/Swiper";
import Part1 from "./components/Part1/Part1";
import Part2 from "./components/Part2/Part2";
import Part3 from "./components/Part3/Part3";
import Part4 from "./components/Part4/Part4";
import Part5 from "./components/Part5/Part5";
import Footer from "../../components/Footer/Footer";
import MobilePart1 from "./components/Part1/MobilePart1";
import swiperBg from "../../assets/wave_line_3.png";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import MobilePart3 from "./components/Part3/MobilePart3";
import MobilePart2 from "./components/Part2/MobilePart2";
import MobilePart4 from "./components/Part4/MobilePart4";
import MobilePart5 from "./components/Part5/MobilePart5";

interface IndexProp {
  isMobile: boolean;
  width: number;
}
function Index(props: IndexProp) {
  const swiperGoTo = useRef();
  const footerRatio = useRef(0);
  const mFooterRatio = useRef(0);
  function rSizeView() {
    //@ts-ignore
    document.body.style.zoom = "normal"; //避免zoom尺寸叠加
    let Y = document.body.clientHeight;
    let X = document.body.clientWidth;
    let scaleY = Y / 1440;
    let mScaleY = Y / 844;
    let scaleX = X / 2560;
    let scale: number;
    footerRatio.current = (315 * 100) / Y + 400;
    mFooterRatio.current = (335 * 100) / Y + 400;
    if (X / Y <= 1.5) {
      scale = scaleX;
    } else {
      scale = scaleY;
    }
    const containers = document.querySelectorAll(".container");
    const mContainers = document.querySelectorAll(".mobile-container");
    [].forEach.call(containers, function (item) {
      //@ts-ignore
      item.style.transform = `scale(${scale})`;
    });
    [].forEach.call(mContainers, function (item) {
      const scale = mScaleY + 0.1 < 1 ? mScaleY + 0.1 : 1;
      //@ts-ignore
      item.style.transform = `scale(${scale})`;
    });
  }
  const { isMobile } = props;
  const [index, setIndex] = useState(0);
  const handleIndex = (currentIndex: number) => {
    setIndex(currentIndex);
  };
  useEffect(() => {
    window.addEventListener(
      "resize",
      function () {
        rSizeView();
      },
      false
    );
    // window.addEventListener(
    //   "orientationchange",
    //   function () {
    //     console.log("rotate");
    //     rSizeView();
    //   },
    //   false
    // );
    return () => {
      window.removeEventListener("resize", () => {});
      // window.removeEventListener("orientationchange", () => {});
    };
  }, []);

  useLayoutEffect(() => {
    rSizeView();
  }, [isMobile]);
  return (
    <>
      <Header
        isTransparent={true}
        isDark={index === 0 ? true : false}
        bgColor="#000000"
        notShow={index === 5}
      ></Header>
      {isMobile ? (
        <Swiper
          isMobile={true}
          indexControl={handleIndex}
          scrollDistance={[
            "0vh",
            "100vh",
            "200vh",
            "300vh",
            "400vh",
            `${mFooterRatio.current}vh`,
          ]}
        >
          <MobilePart1></MobilePart1>
          <MobilePart2></MobilePart2>
          <MobilePart3></MobilePart3>
          <MobilePart4></MobilePart4>
          <MobilePart5></MobilePart5>
          <Footer></Footer>
        </Swiper>
      ) : (
        <Swiper
          goTo={swiperGoTo}
          indexControl={handleIndex}
          indicatorColor={index === 0 ? undefined : "#2d7921"}
          swiperBg={swiperBg}
          haveFooter={true}
          scrollDistance={[
            "0vh",
            "100vh",
            "200vh",
            "300vh",
            "400vh",
            `${footerRatio.current}vh`,
          ]}
        >
          <Part1 goTo={swiperGoTo} isShow={index === 0}></Part1>
          <Part2 isShow={index === 1}></Part2>
          <Part3 isShow={index === 2}></Part3>
          <Part4 isShow={index === 3}></Part4>
          <Part5 isShow={index === 4}></Part5>
          <Footer></Footer>
        </Swiper>
      )}
    </>
  );
}

export default Index;
