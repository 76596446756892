import React from "react";

import './PersonNum.scss'
export default function PersonNum(props: {
    personNum: string;
    tagContent: string;
    style?: React.CSSProperties | undefined
    numStyle?: React.CSSProperties | undefined
    renStyle?: React.CSSProperties | undefined
    tagStyle?: React.CSSProperties | undefined
}) {
    return (
        <div className="person-wrap" style={{...props.style}}>
            <div>
                  <span className="person-num"
                        style={{
                            ...props.numStyle
                        }}>{props.personNum}</span>
                <span className="person-ren"
                      style={{
                          ...props.renStyle
                      }}>人</span>
            </div>
            <div className="person-tag"
                 style={{
                     ...props.tagStyle
                 }}
            >{props.tagContent}</div>
        </div>
    )
}
