import React from "react";
import Title from "../Title/Title";
import "./MobilePart4.scss";
import passageBg1 from "../../../../assets/index_part4/IMG_2093.png";
import passageBg3 from "../../../../assets/index_part4/IMG_2094.png";
import passageBg2 from "../../../../assets/index_part4/IMG_2095.png";
const passages = [
  {
    bg: passageBg1,
    title: "青柚后端小册",
  },
  {
    bg: passageBg2,
    title: "青柚PI日",
  },
  {
    bg: passageBg3,
    title: "那些年的青柚",
  },
];
function MobilePart4() {
  return (
    <div className="mobile-part4-wrap mobile-container">
      <div className="mobile-part4-container">
        <Title
          title="青柚故事：无限热爱与可能"
          detail="不忘初心，以青春之名，为信息化建设进步做贡献"
        ></Title>
        <div className="carousel-wrap"></div>
        <div className="bottom-wrap">
          <div className="bottom-content">
            <div className="story-wrap">
              {passages.map((value, index) => {
                return (
                  <div key={index} className="passage-wrap">
                    <div
                      className="passage-bg"
                      style={{
                        backgroundImage: `url(${value.bg})`,
                        backgroundRepeat: " no-repeat",
                        backgroundSize: "cover",
                      }}
                    ></div>
                    <div className="passage-title">{value.title}</div>
                  </div>
                );
              })}
            </div>
            <div className="some-number">
              <div className="establish-time"></div>
              <div className="product">
                <div className="all"></div>
                <div className="active"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-wrap">
          <div
            className="nav-content"
            onClick={() => {
              let domain = document.domain;
              let protocol = document.location.protocol;
              let port = document.location.port;
              window.location.href = `${protocol}//${domain}${port}/blog`;
            }}
          >
            倾听青柚故事
            <div className="nav-icon"></div>
          </div>
        </div>
        <div className="qxy-wrap"></div>
      </div>
    </div>
  );
}

export default MobilePart4;
