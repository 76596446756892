import React from "react";

import "./index.scss";

function MemberCard(props: {
  cover: string;
  name: string;
  description: string;
}) {
  return (
    <div className="MemberCard">
      <div className="card-cover">
        <img alt={props.name} src={props.cover} />
      </div>
      <div className="card-content">
        <div className="card-name">{props.name}</div>
        <div className="card-description">{props.description}</div>
      </div>
    </div>
  );
}

export default MemberCard;
