import React from "react";
import "./MobilePart1.scss";
function MobilePart1() {
  return (
    <div className="mobile-part1-wrap">
      <video
        style={{
          width: " auto",
          height: "auto",
          minHeight: "100%",
          minWidth: "100%",
        }}
        autoPlay
        muted
        loop
        preload="metadata"
        src="https://wechat.static.qingyou.ren/qingyou-website/mobile.mp4"
      ></video>
    </div>
  );
}

export default MobilePart1;
